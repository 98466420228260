<template>
  <div>
    <b-card>
      <!--- header --->
      <b-card-title>
        <h2>Informacje o dacie </h2>
      </b-card-title>
      <div class="form-group">
        <label for="title">Pracownik: </label>
        <b-form-select
          id="input-3"
          v-model="date.success.employee_id"
          :options="employees"
          required
        />
      </div>
      <div>
        <div class="form-group">
          <label for="title">Dzień tygodnia: </label>
          <input
            id="title"
            v-model="date.success.week_day"
            type="text"
            class="form-control"
          >
        </div>
      </div>
      <div>
        <div class="form-group">
          <label for="title">Godzina: </label>
          <input
            id="title"
            v-model="date.success.time"
            type="time"
            class="form-control"
          >
        </div>
      </div>
    </b-card>
    <b-card>
      <button
        class="btn btn-success"
        style="width:100%;"
        @click="save()"
      >
        <feather-icon icon="SaveIcon" /> Zapisz
      </button>
      <button
        class="btn btn-danger"
        style="margin-top: 10px; width: 100%"
        @click="DeleteDate()"
      >
        <feather-icon icon="TreshIcon" /> Usuń datę
      </button>
      <router-link :to="{ name:'Offers' }">
        <button
          class="btn btn-primary"
          style="margin-top:10px; width: 100%;"
        >
          Powrót
        </button>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import {
  BCard, BCardText, BForm, BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'SingleOffer',
  components: {
    BCard,
    BCardText,
    BForm,
    BFormSelect,
  },
  data() {
    return {
      form: {
        week_day: '',
        employee_id: '',
        time: '',
      },
      date: [],
      employees: [],
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}View-offer-date/${this.$route.params.id}`, config)
      .then(response => {
        this.date = response.data
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
    axios.get(`${process.env.VUE_APP_API_URL}employee`, config)
      .then(response => {
        this.employees = response.data
        console.log('ok for now')
        console.log(this.employees.length)
        for (let i = 0; i < this.employees.length; i++) {
          this.employees[i].text = this.employees[i].description
          this.employees[i].value = this.employees[i].id
          console.log('ok')
        }
      })
  },
  methods: {
    save() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      this.date.employee_id = this.date.employee_id
      axios.put(`${process.env.VUE_APP_API_URL}Update-offer-date/${this.$route.params.id}`, this.date.success, config)
        .then(response => {
          swal.fire({
            title: 'Sukces',
            text: response.data.message,
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
  DeleteDate() {

  },
}
</script>

<style>

</style>
